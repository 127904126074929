import React from "react"
import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import styled, { keyframes } from 'styled-components'
import fadeIn from 'react-animations/lib/fade-in'

const fadeInAnimation = keyframes`${fadeIn}`;

const StyledDiv = styled.div`
  animation: 1s ${fadeInAnimation};

  .text-shadow {
    text-shadow: 0 3px 3px #e8f1f8;
  }
`

const HomeHeroText = () => (
  <StyledDiv className='sm:w-full md:w-full w-11/12 xl:w-8/12 px-4 sm:pt-6 sm:pb-8'>
    <div className='w-full flex flex-col items-center'>
      <h1 className='text-6xl lg:text-8xl text-blue-900 text-center font-extrabold leading-tight text-shadow tracking-wider uppercase mt-24'>
        The Freshest<br /> Laundromats in Tacoma
      </h1>

      <div className='md:w-7/12 lg:w-6/12 xl:w-8/12 max-w-xl flex flex-col md:flex-row items-center mx-auto'>
        <div className='md:w-1/2 md:mx-3 mt-6 mb-3 md:mb-0'>
          <button
            className='w-full block font-display bg-ff-blue-9 hover:bg-ff-blue-10 text-white text-lg md:text-xl lg:text-2xl text-center uppercase font-bold border-4 border-ff-blue-8 hover:border-ff-blue-9 rounded-full py-2 md:py-4 px-12 md:px-8'
            onClick={event => {
              event.preventDefault()
              // TODO: do something with form values
              navigate("#locations")
            }}
          >
            Locations
          </button>
        </div>

        <div className='md:w-1/2 md:mx-3 mt-6 mb-3 md:mb-0'>
          <Link to='/'>
            <button
              className="w-full bg-transparent hover:bg-ff-blue-9 text-ff-blue-8 hover:text-white text-lg md:text-xl lg:text-2xl uppercase font-bold border-4 border-ff-blue-8 hover:border-ff-blue-9 rounded-full py-2 md:py-4 px-12 md:px-8"
              onClick={event => {
                event.preventDefault()
                // TODO: do something with form values
                navigate("#fresh-finds")
              }}
            >
              Learn More
            </button>
          </Link>
        </div>
      </div>
    </div>
  </StyledDiv>
)

export default HomeHeroText