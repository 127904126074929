import React from 'react'
import { Link } from 'gatsby'

import Logo from '../../assets/fresh-folds-logo.svg'
import HeaderMenuList from './HeaderMenuList'

const HeaderNav = () => (
  <nav className="w-full absolute top-0 left-0 right-0 flex items-start justify-center lg:justify-between bg-transparent pt-2 lg:pt-0 mx-auto z-50">
    <div>
      <Link to="/">
        <Logo className='w-32 md:w-48 h-auto' />
      </Link>
    </div>

    <HeaderMenuList />
  </nav>
)

export default HeaderNav