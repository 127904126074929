import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const HomeIntroSection = () => {
  return (
    <div id='about' className="relative">
      <div className="w-11/12 md:w-full 2xl:max-w-screen-xl mx-auto pt-24 pb-0">
        <div className="flex flex-wrap text-center justify-center">
          <div className="w-full md:w-10/12 lg:w-8/12">
            <h2 className="text-5xl md:text-6xl font-bold uppercase leading-tight">
              Get Fresh
            </h2>
            <p className="text-xl md:text-2xl leading-relaxed mt-4 mb-4">
              Fresh Folds laundry is everything you want in a laundromat and more. Get your laundry done fast — really fast — with our state-of-the-art Electrolux washers and dryers. We have it all — free WiFi, free parking, comfortable seating, laundry attendants, and more. Don’t feel like laundry? No problem, drop off your loads and we’ll wash and fold it for you.
            </p>

            <p className="text-xl md:text-2xl leading-relaxed mt-4 mb-4">We accept all major credit/debit cards, EBT, Google Pay and Apple Pay.</p>
          </div>
        </div>
      </div>

      <div id="locations" className='pt-1'>
        <div className="w-11/12 md:w-full mx-auto">
          <h2 className="text-5xl md:text-6xl mt-24 font-bold uppercase text-center leading-tight">
            Locations
          </h2>

          <div className='w-11/12 grid grid-cols-1 md:grid-cols-3 gap-8 mx-auto'>
            <div className='w-full flex flex-col items-center justify-center'>
              <p className="text-2xl md:text-3xl font-semibold text-center mt-12 mb-4">
                1601 E 72nd St., Tacoma, WA 98404
              </p>

              <div className="flex flex-col items-center justify-center mt-12 mb-16">
                <a
                  href='https://www.google.com/maps/place/Fresh+Folds+Laundry/@47.1919554,-122.4096248,17z/data=!3m1!4b1!4m5!3m4!1s0x5490ffdb2284d293:0x463f7e7e6f3e3398!8m2!3d47.1919518!4d-122.4074361'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='w-full md:w-auto font-display bg-ff-blue-9 hover:bg-ff-blue-10 text-white text-lg md:text-xl lg:text-2xl text-center uppercase font-bold border-4 border-ff-blue-8 hover:border-ff-blue-9 rounded-full py-2 px-12 md:px-8'
                >
                  Get Directions
                </a>

                <div className='mt-12'>
                  <a href='https://www.google.com/maps/place/Fresh+Folds+Laundry/@47.1919554,-122.4096248,17z/data=!3m1!4b1!4m5!3m4!1s0x5490ffdb2284d293:0x463f7e7e6f3e3398!8m2!3d47.1919518!4d-122.4074361' target='_blank' rel='noopener noreferrer'>
                    <StaticImage
                      src="../../images/sections/google-map.png"
                      width={610}
                      quality={95}
                      formats={["AUTO", "WEBP", "AVIF"]}
                      alt="Fresh Folds Laundry location"
                      className="w-full mx-auto"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className='w-full flex flex-col items-center justify-center'>
              <p className="text-2xl md:text-3xl font-semibold text-center mt-12 mb-4">
                2310 Mildred St., Suite 126, University Place, WA 98467
              </p>

              <div className="flex flex-col items-center justify-center mt-12 mb-16">
                <a
                  href='https://goo.gl/maps/kfBxY5hUpViZfNSy5'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='w-full md:w-auto font-display bg-ff-blue-9 hover:bg-ff-blue-10 text-white text-lg md:text-xl lg:text-2xl text-center uppercase font-bold border-4 border-ff-blue-8 hover:border-ff-blue-9 rounded-full py-2 px-12 md:px-8'
                >
                  Get Directions
                </a>

                <div className='mt-12'>
                  <a href='https://goo.gl/maps/kfBxY5hUpViZfNSy5' target='_blank' rel='noopener noreferrer'>
                    <StaticImage
                      src="../../images/sections/google-map-2.png"
                      width={608}
                      quality={95}
                      formats={["AUTO", "WEBP", "AVIF"]}
                      alt="Fresh Folds Laundry location"
                      className="w-full mx-auto"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className='w-full flex flex-col items-center justify-center'>
              <p className="text-2xl md:text-3xl font-semibold text-center mt-12 mb-4">
                14620 Pacific Ave S, Parkland, WA 98444
              </p>

              <div className="flex flex-col items-center justify-center mt-12 mb-16">
                <a
                  href='https://goo.gl/maps/4eP9M9PtFKujrGhN9'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='w-full md:w-auto font-display bg-ff-blue-9 hover:bg-ff-blue-10 text-white text-lg md:text-xl lg:text-2xl text-center uppercase font-bold border-4 border-ff-blue-8 hover:border-ff-blue-9 rounded-full py-2 px-12 md:px-8'
                >
                  Get Directions
                </a>

                <div className='mt-12'>
                  <a href='https://goo.gl/maps/4eP9M9PtFKujrGhN9' target='_blank' rel='noopener noreferrer'>
                    <StaticImage
                      src="../../images/sections/google-map-3-now-open.png"
                      width={610}
                      quality={95}
                      formats={["AUTO", "WEBP", "AVIF"]}
                      alt="Fresh Folds Laundry location"
                      className="w-full mx-auto"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="w-11/12 md:w-full xl:max-w-screen-xl mx-auto  pt-0 pb-24">
        <div className="flex flex-wrap text-center justify-center">
          <div className="w-full md:w-10/12 lg:w-8/12">
            <a href='tel:253-507-4633' className="block text-3xl md:text-4xl font-bold mb-4">
              253-507-4633
            </a>

            <p className="text-xl md:text-3xl font-medium mb-4">8am - 9pm 7 days a week</p>

            <p className="text-xl md:text-3xl font-medium mb-4">last wash at 7:30pm</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeIntroSection