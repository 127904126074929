import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'

const StyledDiv = styled.div`
  background: #00ADEE;
`

const container = 'w-11/12 md:w-6/12'
const wrapper = 'w-full p-0 mt-12 lg:p-6 lg:mt-0'
const pStyle = 'text-3xl lg:text-4xl text-center font-medium text-white mb-6'

const HomePromoSection = () => {
  return (
    <StyledDiv>
      <div id='our-machines' className="w-11/12 md:w-full xl:max-w-screen-xl mx-auto py-24">
        <div className="flex flex-col items-center justify-center">
          <div className="w-full md:w-10/12 lg:w-8/12 mx-auto">
            <h2 className="text-5xl md:text-6xl text-white text-center font-bold uppercase leading-tight">
              Our Machines
            </h2>
          </div>

          <div className='w-11/12 xl:max-w-screen-xl flex flex-col md:flex-row items-center justify-center mx-auto mt-12'>
            <div className={container}>
              <div className={wrapper}>
                <StaticImage
                  src="../../images/sections/doing-laundry.png"
                  width={583}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Fresh Folds Laundry"
                  className="w-full h-auto"
                />
              </div>
            </div>

            <div className={container}>
              <div className={wrapper}>
                <p className={pStyle}>2 Load Washer <br className='xl:hidden'/>= 20 lbs</p>
                <p className={pStyle}>4 Load Washer <br className='xl:hidden'/>= 40 lbs</p>
                <p className={pStyle}>6 Load Washer <br className='xl:hidden'/>= 60 lbs</p>
                <p className={pStyle}>8 Load Washer <br className='xl:hidden'/>= 80 lbs</p>
                <p className={pStyle}>Dryers run for 8 minutes at a time and <br className='xl:hidden'/>an average load is 24 minutes or less.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledDiv>
  )
}

export default HomePromoSection