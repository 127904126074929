import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const container = 'w-full md:w-1/2'
const wrapper = 'w-full p-0 mt-12 lg:p-6 lg:mt-0'
const pStyle = 'text-2xl text-center font-bold text-gray-800 mb-6'

const HomeCardSection = () => {
  return (
    <div className="relative" style={{ display: "grid", background: "#afdde9" }}>
      {/* You can use a GatsbyImage component if the image is dynamic */}
      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          // maxHeight: 600,
          objectFit: "cover",
          opacity: "0.7",
          height: 800,
        }}
        // This is a presentational image, so the alt should be an empty string
        alt=""
        src={
          "../../images/sections/section-bg-laundry-card.jpg"
        }
        formats={["auto", "webp", "avif"]}
        width={1440}
      />
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          placeItems: "center",
          display: "grid",
        }}
      >
        {/* Any content here will be centered in the component */}
        <div className="w-11/12 md:w-full xl:max-w-screen-xl mx-auto py-24">
          <div className="flex flex-col justify-center">
            <div className="w-full md:w-10/12 lg:w-8/12 mx-auto">
              <h2 className="text-gray-900 text-5xl md:text-6xl text-center font-bold uppercase leading-tight">
                A Fresh New Concept
              </h2>
            </div>

            <div className='w-11/12 xl:max-w-screen-xl flex flex-col md:flex-row items-center mx-auto mt-12'>
              <div className={container}>
                <div className={wrapper}>
                  <StaticImage
                    src="../../images/sections/fresh-folds-card.png"
                    width={626}
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Fresh Folds Card"
                    className="w-full h-auto"
                  />
                </div>
              </div>

              <div className={container}>
                <div className={wrapper}>
                  <p className={pStyle}>Kiss coins goodbye with your own reloadable Fresh Folds card. Sanitary, convenient, and much lighter.</p>

                  <p className={pStyle}>Lose your card? No problem, register your card and we can issue you a new one with your balance on it.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeCardSection