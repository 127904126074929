import React from 'react'

const styledP = "text-lg md:text-xl text-white font-medium leading-relaxed mb-12"

const HomeHiringSection = () => (
  <div id='contact' className="bg-gray-900">
    <div className="w-11/12 md:w-full xl:max-w-screen-xl mx-auto py-24">
      <div className="flex flex-wrap text-center justify-center">
        <div className="w-full md:w-10/12 lg:w-8/12">
          <h2 className="text-4xl md:text-5xl text-white font-bold uppercase leading-tight mb-12">
            We're Hiring
          </h2>

          <p className={styledP}>
            Competitive wages and a fun environment make fresh folds a great place to work.
          </p>

          <a
            href='https://forms.gle/nXHB75DVSh7gtanLA'
            target='_blank'
            rel='noopener noreferrer'
          >
            <button className="bg-transparent hover:bg-white text-white hover:text-ff-blue-9 text-base md:text-lg lg:text-2xl uppercase font-bold border-4 border-white hover:border-gray-900 rounded-full py-4 px-12 md:px-8">
              Apply Now
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
)

export default HomeHiringSection