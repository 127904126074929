import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'

const StyledDiv = styled.div`
  .text-shadow {
    text-shadow: 0 1px 1px #e8f1f8;
  }
`

const pStyle = 'text-3xl text-ff-blue-9 text-center text-shadow font-semibold mt-12'

const HomePriceSection = () => {
  return (
    <div className="relative" style={{ display: "grid", background: "#afdde9" }}>
      {/* You can use a GatsbyImage component if the image is dynamic */}
      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          // maxHeight: 600,
          objectFit: "cover",
          opacity: "0.7",
          height: 800,
        }}
        // This is a presentational image, so the alt should be an empty string
        alt=""
        src={
          "../../images/sections/section-bg-pricelist.jpg"
        }
        formats={["auto", "webp", "avif"]}
        width={1920}
      />
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          placeItems: "center",
          display: "grid",
        }}
      >
        {/* Any content here will be centered in the component */}
        <StyledDiv id='service' className="w-11/12 md:w-full xl:max-w-screen-xl h-screen mx-auto py-24">
          <div className="flex flex-col items-center justify-center">
            <div className="w-full md:w-10/12 lg:w-8/12 mt-16 mx-auto">
              <h2 className="text-gray-900 text-5xl md:text-6xl text-center text-shadow font-bold uppercase leading-tight">
                Drop Off Service
              </h2>
              <h2 className="text-ff-blue-10 text-4xl md:text-5xl text-center font-bold leading-tight">
                Wash, Dry, FreshFold
              </h2>

              <p className={pStyle}>We Love Laundry!<br/>Let us do it for you.</p>

              <div className='w-full xl:w-2/3 bg-white py-6 px-2 rounded-md mt-12 mx-auto'>
                <p className='text-2xl xl:text-3xl text-ff-blue-9 text-center text-shadow font-semibold'><span className='font-black text-4xl xl:text-5xl'>$1.75 per pound</span><br/>10 pound minimum</p>
              </div>
            </div>
          </div>
        </StyledDiv>
      </div>
    </div>
  )
}

export default HomePriceSection