import React from 'react'

const styledH3 = 'text-3xl md:text-4xl uppercase text-ff-blue-9 font-semibold leading-tight mt-12'
const styledP = "text-xl md:text-2xl text-gray-700 font-medium leading-relaxed mb-4"

const HomeFeaturesSection = () => (
  <div id='features' className="bg-gray-200">
    <div className="w-11/12 md:w-full xl:max-w-screen-xl mx-auto py-24">
      <div className="flex flex-wrap text-center justify-center">
        <div className="w-full md:w-10/12 lg:w-8/12">
          <h2 className="text-5xl md:text-6xl text-ff-blue-10 font-bold uppercase leading-tight">
            Give Us A Spin
          </h2>

          <h3 className={styledH3}>Fast Wash & Dry</h3>
          <p className={styledP}>
            Be done in less than an hour with our ultra fast 450 G-force machines
          </p>

          <h3 className={styledH3}>Free Parking</h3>
          <p className={styledP}>
            Plenty of parking to ensure easy access to our facility
          </p>

          <h3 className={styledH3}>Laundry Attendants</h3>
          <p className={styledP}>
            We always have a laundry attendant on site to lend a helping hand.
          </p>

          <h3 className={styledH3}>Conveniences</h3>
          <p className={styledP}>
            Feel right at home with free wifi and comfortable seating
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default HomeFeaturesSection